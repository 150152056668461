import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ChangeEvent } from 'react';
import styles from './styles.module.css';

export interface UploadImageProps {
  previewUrl: string;
  fileName: string;
  onChange: (file: ChangeEvent<HTMLInputElement>) => void;
  handleRemoveImage?: () => void;
  name?: string;
  isError?: boolean;
  isDeleteBtnShouldBe?: boolean;
  previewSizeHeight?: string;
  previewSizeWidth?: string;
  fileInputRef?: any;
  required?: boolean;
}

export const UploadImage = ({
  previewUrl,
  fileName,
  onChange,
  name,
  isError,
  previewSizeHeight = '50px',
  previewSizeWidth = '50px',
  handleRemoveImage,
  isDeleteBtnShouldBe = true,
  fileInputRef,
  required,
}: UploadImageProps) => {
  return (
    <>
      <div>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          fullWidth
        >
          Upload file
          <input
            ref={fileInputRef}
            className={styles.fileInput}
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={onChange}
            name={name}
          />
        </Button>
        {isError ||
          (required && (
            <Typography color="error" variant="body1">
              This field is required
            </Typography>
          ))}
      </div>

      {previewUrl && (
        <div className={styles.imgPreview}>
          <div
            className={styles.imgContainer}
            style={{ height: previewSizeHeight, width: previewSizeWidth }}
          >
            <img className={styles.img} src={previewUrl} alt="Preview" />
            {isDeleteBtnShouldBe && (
              <Button
                className={styles.removeImageButton}
                variant="outlined"
                color="error"
                onClick={handleRemoveImage}
              >
                Remove photo
              </Button>
            )}
          </div>
          <Typography>{fileName}</Typography>
        </div>
      )}
    </>
  );
};

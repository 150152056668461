import { useEffect, useReducer } from 'react';
import { API } from '../../api/api';
import { URLS } from '../../api/urls';
import { useAlert } from '../../context/Alert.context';
import { GadgetDto } from '../../types/gadgets.types';
import { ModelDto } from '../../types/models.types';
import { ModalKeys } from './chooseModal';
import { initialState, reducer } from './reducer/reducer';
import { actionGadgetTypes } from './reducer/reducer.type';

export const useGadgetsPage = () => {
  const [
    { gadgets, loading, selectedGadget, selectedModel, choosenModal },
    dispatch,
  ] = useReducer(reducer, initialState);
  const { showAlert } = useAlert();

  useEffect(() => {
    API.get<GadgetDto[]>(`${URLS.GADGETS}?models=true&modelsService=true`)
      .then(({ data }) => {
        dispatch({ type: actionGadgetTypes.SET_GADGETS, payload: data });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() =>
        dispatch({ type: actionGadgetTypes.SET_LOADING, payload: false }),
      );
  }, []);

  const actions = {
    setModal: (value: ModalKeys | null) =>
      dispatch({ type: actionGadgetTypes.SET_CHOOSEN_MODAL, payload: value }),
    setSelectedGadget: (gadget: GadgetDto | null) =>
      dispatch({
        type: actionGadgetTypes.SET_SELECTED_GADGET,
        payload: gadget,
      }),
    setSelectedModel: (model: ModelDto | null) =>
      dispatch({ type: actionGadgetTypes.SET_SELECTED_MODEL, payload: model }),
    closeModal: () =>
      dispatch({ type: actionGadgetTypes.SET_CHOOSEN_MODAL, payload: null }),
  };

  const updateGadgetAfterDelete = () => {
    const updatedGadgets: GadgetDto[] = gadgets.filter(
      (gadget: GadgetDto) => gadget.id !== selectedGadget?.id,
    );
    dispatch({ type: actionGadgetTypes.SET_GADGETS, payload: updatedGadgets });
  };

  const updateGadgetAfterModelDelete = () => {
    const updatedGadgets: GadgetDto[] = gadgets.map((gadget: GadgetDto) => {
      if (gadget.slug === selectedModel?.gadgetId) {
        return {
          ...gadget,
          models: gadget.models?.filter(
            (model) => model.id !== selectedModel?.id,
          ),
        };
      }
      return gadget;
    });
    dispatch({ type: actionGadgetTypes.SET_GADGETS, payload: updatedGadgets });
  };

  const updateGadgetAfterCreate = (gadget: GadgetDto) => {
    const updatedGadgets: GadgetDto[] = [...gadgets, gadget];
    dispatch({ type: actionGadgetTypes.SET_GADGETS, payload: updatedGadgets });
  };
  const updateGadgetAfterEdit = (editedGadget: GadgetDto) => {
    const updatedGadgets: GadgetDto[] = gadgets.map((gadget: GadgetDto) => {
      if (gadget.id === editedGadget.id) {
        return {
          ...gadget,
          name: editedGadget.name,
          icon: editedGadget.icon,
        };
      }
      return gadget;
    });
    dispatch({ type: actionGadgetTypes.SET_GADGETS, payload: updatedGadgets });
  };

  const updateGadgetAfterCreateModel = (model: ModelDto) => {
    const updatedGadgets: GadgetDto[] = gadgets.map((gadget: GadgetDto) => {
      if (gadget.id === model.gadgetId) {
        return {
          ...gadget,
          models: gadget?.models ? [...gadget.models, model] : [model],
        };
      }
      return gadget;
    });
    dispatch({ type: actionGadgetTypes.SET_GADGETS, payload: updatedGadgets });
  };

  const updateGadgetAfterEditModel = (model: ModelDto) => {
    const updatedGadgets: GadgetDto[] = gadgets.map((gadget: GadgetDto) => {
      if (gadget.id === model.gadgetId) {
        return {
          ...gadget,
          models: gadget?.models?.map((item) => {
            if (model.id === item.id) {
              return {
                ...item,
                ...model,
              };
            }
            return item;
          }),
        };
      }
      return gadget;
    });
    dispatch({ type: actionGadgetTypes.SET_GADGETS, payload: updatedGadgets });
  };

  return {
    gadgets,
    loading,
    selectedGadget,
    selectedModel,
    choosenModal,
    ...actions,
    updateGadgetAfterDelete,
    updateGadgetAfterCreate,
    updateGadgetAfterEdit,
    updateGadgetAfterModelDelete,
    updateGadgetAfterCreateModel,
    updateGadgetAfterEditModel,
  };
};

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { getIcon } from '../../../components/IconSelect/icons';
import { GadgetDto } from '../../../types/gadgets.types';
import { ModelDto } from '../../../types/models.types';
import styles from '../styles.module.css';

interface RowProps {
  onGadgetEdit: () => void;
  onGadgetDelete: () => void;
  onModelDelete: (model: ModelDto) => void;
  row: GadgetDto;
  onModelEdit: (model: ModelDto) => void;
  onModelAdd: (gadgetId: string, gadgetSlug: string) => void;
}

export const Row = ({
  row,
  onGadgetEdit,
  onGadgetDelete,
  onModelDelete,
  onModelEdit,
  onModelAdd,
}: RowProps) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '20px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          <div className={styles.gadgetName}>
            <div className={styles.icon}>{getIcon(row.icon).icon}</div>
            <Typography>{row.name}</Typography>
          </div>
        </TableCell>
        <TableCell align="right">
          <div className={styles.action}>
            <EditIcon onClick={onGadgetEdit} />
            {/*<DeleteIcon color="error" onClick={onGadgetDelete} />*/}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              className={styles.subTableContainer}
              sx={{ margin: 1, padding: 2 }}
            >
              <div className={styles.header}>
                <Typography variant="h6">Models</Typography>
                <Button
                  variant="contained"
                  onClick={() => onModelAdd(row.id, row.slug || '')}
                  startIcon={<AddIcon />}
                >
                  ADD
                </Button>
              </div>
              <Table size="small">
                <TableBody>
                  {row?.models?.length ? (
                    <>
                      {row.models.map((model: ModelDto) => (
                        <TableRow key={model.id}>
                          <TableCell component="th" scope="row">
                            <div className={styles.imgCell}>
                              {model.file && (
                                <div className={styles.imgContainer}>
                                  <img
                                    className={styles.img}
                                    src={`${process.env.REACT_APP_API_URL_STATIC}/models/${model.file.fileName}`}
                                  />
                                </div>
                              )}

                              <span>{model.name}</span>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.action}>
                              <EditIcon
                                onClick={() => {
                                  onModelEdit({
                                    ...model,
                                    gadgetId: row.id,
                                    gadgetSlug: row.slug,
                                  });
                                }}
                              />
                              <DeleteIcon
                                color="error"
                                onClick={() =>
                                  onModelDelete({
                                    ...model,
                                    gadgetId: row.slug,
                                  })
                                }
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <Typography variant="h6">No Content</Typography>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

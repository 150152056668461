import { LoadingButton } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { FormEvent, useEffect, useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { UploadImage } from '../../../../components/UploadImage/UploadImage';
import { useAlert } from '../../../../context/Alert.context';
import { GadgetDto } from '../../../../types/gadgets.types';
import { ModelDto } from '../../../../types/models.types';
import { ServiceInfo } from '../../../../types/serviceInfo.types';
import { Service } from '../../../../types/services.types';
import { ModelEdit } from '../../GadgetsPage.type';
import styles from './ModelModal.module.css';
import { Services } from './Services/Services';

interface ModelFormProps {
  onSubmit: (model: ModelEdit) => void;
  onCancel: () => void;
  defaultValue?: ModelDto;
  loading: boolean;
  gadgetSlug: string;
}

export const ModelForm = ({
  onSubmit,
  defaultValue,
  onCancel,
  loading,
  gadgetSlug,
}: ModelFormProps) => {
  const { showAlert } = useAlert();
  const [name, setName] = useState(defaultValue?.name || '');
  const [file, setFile] = useState<File | null>(null);
  const [serviceInfo, setServiceInfo] = useState<ServiceInfo[]>([]);
  const [services, setServices] = useState<Service[]>(
    defaultValue?.services || [],
  );
  const [previewUrl, setPreviewUrl] = useState(
    defaultValue?.file?.fileName
      ? `${process.env.REACT_APP_API_URL_STATIC}/models/${defaultValue.file.fileName}`
      : null,
  );

  useEffect(() => {
    API.get<GadgetDto>(`${URLS.GADGETS}/${gadgetSlug}?serviceInfo=true`)
      .then(({ data }) => {
        setServiceInfo(data?.serviceInfo || []);
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }));
  }, []);

  const onSubmitHdl = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isSubmitBtnDisabled) return;
    onSubmit({ file, name, services });
  };

  const isSubmitBtnDisabled =
    !name.trim() || (!file && !defaultValue?.file) || !services.length;

  const addService = (service: Service) => {
    const isExist = services.filter(
      (serviceItem) => service.serviceInfoId === serviceItem.serviceInfoId,
    )[0];
    if (isExist) {
      showAlert({
        message: 'The service has already been created',
        severity: 'warning',
      });
      return;
    }
    const newServices = [...services, service];
    setServices(newServices);
  };

  const removeService = (id: string) => {
    const newServices = services.filter((service) => service.id !== id);
    setServices(newServices);
  };

  const editService = (editedService: Service) => {
    const newServices = services.map((service) => {
      if (service.id === editedService.id) {
        return {
          ...service,
          ...editedService,
        };
      }
      return service;
    });
    setServices(newServices);
  };

  if (!serviceInfo.length) {
    return (
      <div>
        <Typography variant="h4">
          Please, create service Info fot this gadget.
        </Typography>
      </div>
    );
  }

  return (
    <form className={styles.form} onSubmit={onSubmitHdl} noValidate>
      <TextField
        name="name"
        label="Model"
        value={name}
        variant="standard"
        required
        onChange={(e) => setName(e.target.value)}
      />
      <UploadImage
        isDeleteBtnShouldBe={false}
        previewUrl={previewUrl || ''}
        fileName={file?.name || ''}
        required
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(e.target.files[0]);
            setPreviewUrl(objectUrl);
          }
        }}
      />
      {!!serviceInfo.length && (
        <Services
          serviceInfo={serviceInfo}
          onDelete={removeService}
          onAdd={addService}
          onEdit={editService}
          services={services}
        />
      )}
      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={onCancel}>
          CANCEL
        </Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          disabled={isSubmitBtnDisabled}
        >
          SAVE
        </LoadingButton>
      </DialogActions>
    </form>
  );
};

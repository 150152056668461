import { ServiceInfo } from '../../../types/serviceInfo.types';
import { type FieldValues } from './types';

export const mapToFieldValues = (
  serviceInfo: ServiceInfo | undefined | null,
): FieldValues => ({
  fileInfo: serviceInfo?.fileInfo ?? null,
  icon: serviceInfo?.icon ?? null,

    titleEn: serviceInfo?.titleEn ?? '',
    titlePl: serviceInfo?.titlePl ?? '',
    titleUk: serviceInfo?.titleUk ?? '',
    titleRu: serviceInfo?.titleRu ?? '',

    shortTitleEn: serviceInfo?.shortTitleEn ?? '',
    shortTitlePl: serviceInfo?.shortTitlePl ?? '',
    shortTitleUk: serviceInfo?.shortTitleUk ?? '',
    shortTitleRu: serviceInfo?.shortTitleRu ?? '',

    subTitleEn: serviceInfo?.subTitleEn ?? '',
    subTitlePl: serviceInfo?.subTitlePl ?? '',
    subTitleUk: serviceInfo?.subTitleUk ?? '',
    subTitleRu: serviceInfo?.subTitleRu ?? '',

    metaDescriptionEn: serviceInfo?.metaDescriptionEn ?? '',
    metaDescriptionPl: serviceInfo?.metaDescriptionPl ?? '',
    metaDescriptionUk: serviceInfo?.metaDescriptionUk ?? '',
    metaDescriptionRu: serviceInfo?.metaDescriptionRu ?? '',

    infoEn: {
    title: serviceInfo?.infoEn.title ?? '',
      titleContent: serviceInfo?.infoEn.title ?? '',
      subTitle: serviceInfo?.infoEn.title ?? '',
      subTitleContent: serviceInfo?.infoEn.subTitleContent ?? '',
      titleList: serviceInfo?.infoEn.titleList ?? '',
      list: serviceInfo?.infoEn.list ?? '',
      listContent: serviceInfo?.infoEn.listContent ?? '',
      titleTip: serviceInfo?.infoEn.titleTip ?? '',
      tipContent: serviceInfo?.infoEn.tipContent ?? '',
  },
  infoPl: {
    title: serviceInfo?.infoPl.title ?? '',
    titleContent: serviceInfo?.infoPl.title ?? '',
    subTitle: serviceInfo?.infoPl.title ?? '',
    subTitleContent: serviceInfo?.infoPl.subTitleContent ?? '',
    titleList: serviceInfo?.infoPl.titleList ?? '',
    list: serviceInfo?.infoPl.list ?? '',
    listContent: serviceInfo?.infoPl.listContent ?? '',
    titleTip: serviceInfo?.infoPl.titleTip ?? '',
    tipContent: serviceInfo?.infoPl.tipContent ?? '',
  },
  infoUk: {
    title: serviceInfo?.infoUk.title ?? '',
    titleContent: serviceInfo?.infoUk.title ?? '',
    subTitle: serviceInfo?.infoUk.title ?? '',
    subTitleContent: serviceInfo?.infoUk.subTitleContent ?? '',
    titleList: serviceInfo?.infoUk.titleList ?? '',
    list: serviceInfo?.infoUk.list ?? '',
    listContent: serviceInfo?.infoUk.listContent ?? '',
    titleTip: serviceInfo?.infoUk.titleTip ?? '',
    tipContent: serviceInfo?.infoUk.tipContent ?? '',
  },
  infoRu: {
    title: serviceInfo?.infoRu.title ?? '',
    titleContent: serviceInfo?.infoRu.title ?? '',
    subTitle: serviceInfo?.infoRu.title ?? '',
    subTitleContent: serviceInfo?.infoRu.subTitleContent ?? '',
    titleList: serviceInfo?.infoRu.titleList ?? '',
    list: serviceInfo?.infoRu.list ?? '',
    listContent: serviceInfo?.infoRu.listContent ?? '',
    titleTip: serviceInfo?.infoRu.titleTip ?? '',
    tipContent: serviceInfo?.infoRu.tipContent ?? '',
  },
});

import { useState } from 'react';
import { API } from '../../../../api/api';
import { URLS } from '../../../../api/urls';
import { DeleteConfirmationModal } from '../../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import { ALERT_TEXT } from '../../../../constants/content';
import { useAlert } from '../../../../context/Alert.context';
import { ModelDto } from '../../../../types/models.types';

interface DeleteGadgetModalProps {
  open: boolean;
  onClose: () => void;
  model: ModelDto;
  updateGadgetAfterModelDelete: () => void;
}

export const DeleteModelModal = ({
  open,
  onClose,
  model,
  updateGadgetAfterModelDelete,
}: DeleteGadgetModalProps) => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const onDelete = () => {
    setLoading(true);
    API.delete(`${URLS.MODELS}/${model.id}`)
      .then((res) => {
        updateGadgetAfterModelDelete();
        onClose();
        showAlert({
          message: ALERT_TEXT.MODEL_DELETE_SUCCESS,
          severity: 'success',
        });
      })
      .catch((e) => showAlert({ message: e.message, severity: 'error' }))
      .finally(() => setLoading(false));
  };

  return (
    <DeleteConfirmationModal
      loading={loading}
      onClose={onClose}
      open={open}
      titleItem={model.name}
      onDelete={onDelete}
    />
  );
};

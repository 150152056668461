import { Service } from '../../../../../../types/services.types';
import { FieldValues } from './types';

export const mapToFieldValues = (
  service: Service | undefined | null,
): FieldValues => ({
  price: service?.price ?? 0,
  oldPrice: service?.oldPrice ?? 0,
  isBestPrice: service?.isBestPrice ?? false,
  recoveryTime: service?.recoveryTime ?? '',
  isFreeDiagnostic: service?.isFreeDiagnostic ?? false,
  guarantee: service?.guarantee ?? 0,
  serviceInfoId: service?.serviceInfoId ?? '',
});
